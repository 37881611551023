<template>
  <template v-for="item in menuTree" :key="item._id">
    <el-submenu v-if="item.children && item.children.length>0 && item.children[0].menuType == 1" :key="item._id" :index="item.path">
			<template #title>
				<i :class="item.icon"></i>
				<span>{{item.menuName}}</span>
			</template>
			<tree-menu :menuTree="item.children"/>
	</el-submenu>
	<el-menu-item v-else-if="item.menuType == 1" :index="item.path" :key="item._id">{{item.menuName}}</el-menu-item>
  </template>
</template>

<script>
export default {
	name:'TreeMenu',
	props:{
		menuTree:{
			type:Array,
			default:()=>[]
		}
	}
}
</script>

<style>

</style>