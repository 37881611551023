import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Login from '../components/Login.vue'
import { useStore } from "vuex";
import axios from "axios"
import { ElMessage } from "element-plus";



const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        redirect: '/login',
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('../components/Login.vue'),

            }
        ]
    },
    {
        path: '/',
        name: 'welcome',
        component: Home,
        redirect: '/welcome',
        children: [
            {
                path: '/semAccount',
                name: 'semAccount',
                component: () => import('../views/SemAccount.vue'),
            },
            {
                path: '/enterpriseAccount',
                name: 'enterpriseAccount',
                component: () => import('../views/CustomerBusinessAccount.vue'),
            },
            {
                path: '/accountReport',
                name: 'accountReport',
                component: () => import('../views/AccountReport.vue'),

            },
            {
                path: '/accountDetail',
                name: 'accountDetail',
                component: () => import('../views/AccountDetail.vue'),

            },
            {
                path: '/welcome',
                name: 'welcome',
                component: () => import('../views/Welcome.vue'),

            }
          
            ,
            {
                path: '/semDict',
                name: 'semDict',
                component: () => import('../views/SystemDict.vue')

            } ,
            {
                path: '/accountTab',
                name: 'accountTab',
                component: () => import('../views/AccountTab.vue')

            } ,
            {
                path: '/accountClue',
                name: 'accountClue',
                component: () => import('../views/AccountClue.vue')

            },
            {
                path: '/accountPlan',
                name: 'accountPlan',
                component: () => import('../views/AccountPlan.vue')

            },{
                path: '/accountPlanUnit',
                name: 'accountPlanUnit',
                component: () => import('../views/AccountPlanUnit.vue')

            },{
                path: '/accountPage',
                name: 'accountPage',
                component: () => import('../views/AccountPage.vue')

            },{
                path: '/accountCheckConfig',
                name: 'accountCheckConfig',
                component: () => import('../views/AccountCheckConfig.vue')
            },{
                path: '/accountLog',
                name: 'accountLog',
                component: () => import('../views/AccountLog.vue')
            },
            {
                path: '/accountLogHome',
                name: 'accountLogHome',
                component: () => import('../views/AccountLogHome.vue')
            },
            {
                path: '/accountOptTab',
                name: 'accountOptTab',
                component: () => import('../views/AccountOptTab.vue')

            } ,
            {
                path: '/businessAccountTab',
                name: 'businessAccountTab',
                component: () => import('../views/BusinessAccountTab.vue')

            } ,
            {
                path: '/accountCheck',
                name: 'accountCheck',
                component: () => import('../views/AccountCheckLog.vue')
            } ,
            {
                path: '/businessLive',
                name: 'businessLive',
                component: () => import('../views/BusinessLive.vue')
            } 
            ,
            {
                path: '/businessMsg',
                name: 'businessMsg',
                component: () => import('../views/BusinessMsg.vue')
            }  ,
            {
                path: '/businessAccountVideo',
                name: 'businessAccountVideo',
                component: () => import('../views/BusinessAccountVideo.vue')
            } 
            ,
            {
                path: '/accountVideo',
                name: 'accountVideo',
                component: () => import('../views/AccountVideo.vue')
            },
            {
                path: '/semUserDay',
                name: 'semUserDay',
                component: () => import('../views/UserAccountDay.vue')
            } 
            ,
            {
                path: '/accountLog',
                name: 'accountLog',
                component: () => import('../views/AccountLog.vue')
            }  
        ]
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {

    //无登录
        // if (to.matched.length === 0) {
        //     // next({name: ''})----通过名称导航到特定路由。name: 是我要导航到的路由名称
        //     from.name ? next({ name: from.name }) : next('/401');
        // } else {
        //     next();
        // }
    //登录
    // if(to.name==='login'){
    //     next();
    //     NProgress.done();
    // }
    // var cacheToken=localStorage.getItem("userToken")
    // if (!cacheToken) {
    //     next('/login')
    // }else{
    //     if (to.matched.length === 0) {
    //         // next({name: ''})----通过名称导航到特定路由。name: 是我要导航到的路由名称
    //         from.name ? next({ name: from.name }) : next('/401');
    //     } else {
    //         next();
    //     }
    // }

    //正常系统运行
    var url = decodeURI(window.location.search); //?id="123456"&name="www";
    var object = {};
    var code;
    var state;
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);  //得到?后面的字符串
        var strs = str.split("&");  //将得到的参数分隔成数组[id="123456",name="www"];
        for (var i = 0; i < strs.length; i++) {
            object[strs[i].split("=")[0]] = strs[i].split("=")[1];//得到{id:'123456',name:'www'}
        }
        code = object["code"]
        state = object["state"]
    }
    var cacheToken=localStorage.getItem("userToken")
    if (!cacheToken && !code) {
        window.open('https://login.work.weixin.qq.com/wwlogin/sso/login?login_type=CorpApp&appid=wwdef0613225697b22&agentid=1000013&redirect_uri=http://fgert123ewte.wert234.quanwang.xin/&state=1233weerrf', '_self')
        NProgress.done();
    }
    
    if (!!cacheToken) {
        if (to.matched.length === 0) {
            // next({name: ''})----通过名称导航到特定路由。name: 是我要导航到的路由名称
            from.name ? next({ name: from.name }) : next('/401');
        } else {
            next();
        }
    }
    if (!!code && !cacheToken) {
        wxlogin()
        function wxlogin() {
            axios.post('/crmOnlinePush/login/wx', { code: code, state: state }).then(res => {
                var resultCode=res.data.code;
                if(resultCode==200){
                    var userToken = res.data.data
                    if (!!userToken) {
                        localStorage.setItem("userToken", userToken);
                    }
                }
                  // 1.2.1如果没有从已有的所有路由中匹配到路由，有从上个路由过来的就直接跳到上个路由，否则就跳到401
                  if (to.matched.length === 0) {
                    // next({name: ''})----通过名称导航到特定路由。name: 是我要导航到的路由名称
                    from.name ? next({ name: from.name }) : next('/401');
                } else {
                    next();
                }     
            })
                .catch(function (error) {
                    ElMessage({
                        message: "登录失败",
                        type: "error",
                      });
                })
                
        }
    }
})

export default router
