
const state={
    history:[],
    historyPath:[],
    currentPath:null
}

const mutations={
    addHistoryPath(state,path){
        let paths=state.historyPath.filter(item=>{
            return item!==path;
        })
        paths.push(path);
        state.historyPath=paths;
        state.currentPath=path;
    },
    addHistory(state,history){
        let index=-1;
        state.history.forEach((item,i)=>{
            if(item.path===history.path)
                index=i;
        })
        if(index!==-1){
            return;
        }
        state.history.push({
            name:history.name,
            path:history.path
        })
    },
    removeHistory(state,path){
        state.history=state.history.filter(item=>{
            return item.path!==path;
        })
    },
    removeHistoryPath(state,path){
        state.historyPath=state.historyPath.filter(item=>{
            return item!==path;
        })
        if(state.historyPath.length>0)
            state.currentPath=state.historyPath[state.historyPath.length-1];
        else
            state.currentPath=null;
    }
}

export default{
    namespaced:true,
    state,
    mutations
}

