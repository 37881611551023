import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'  // 引入Element Plus 所需
import 'element-plus/dist/index.css'  // 引入Element Plus 所需
import router from './router/index.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue' // 引入Element Plus icon 所需
import './assets/main.css'
import axios from 'axios'
import store from '@/store/index.js'
import errorHandler from './tools/errorHandler.js'
import { ElMessage } from "element-plus";

const app = createApp(App)

// 引入Element Plus icon 所需
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(store)
app.use(router)
app.use(ElementPlus) 
app.mount('#app')

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
      let context = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    }
  }
  
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
      callback = debounce(callback, 16);
      super(callback);
    }
  }
  axios.interceptors.request.use(
    config => {
        var cacheToken=localStorage.getItem("userToken")
        config.headers.token = cacheToken;
        return config;
    })
    axios.interceptors.response.use(
      function (response) {
        var systemResp=response.data
        var code=systemResp.code;
        var message=systemResp.message;
        if(code !== 200){
          //处理服务器内部错误
          ElMessage({
            message: message,
            type: "error",
          });
          return Promise.reject();
        }else{
          return response;
        }
    }, function (error) {
        // 错误处理
        errorHandler(error);
        return Promise.reject(error);
    }
    );


  

