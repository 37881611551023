<template>
  <div class="basic-layout">
    <div :class="['nav', isCollapse ? 'fold' : 'unfold']">
      <!-- 系统logo -->
      <div class="logo">
        <img src="../assets/img/logo-small.png" class="img2" />
      </div>
      <!-- 导航菜单 -->
      <el-menu
        :default-active="activeMenu"
        class="nav-menu"
        background-color="#001529"
        :router="true"
        text-color="#fff"
        :collapse="isCollapse"
      >
        <el-menu-item index="/welcome">
          <template #title>
            <el-icon><HomeFilled /></el-icon>
            <span>主页</span>
          </template>
        </el-menu-item>
        <el-sub-menu index="1">
          <template #title>
            <el-icon><User /></el-icon>
            <span>推广账户</span>
          </template>
          <el-menu-item
            index="/semAccount"
            @click="openMenuItem('推广账户', '/semAccount')"
            >账户列表</el-menu-item
          >
          <el-menu-item
            index="/accountCheck"
            @click="openMenuItem('推广考核', '/accountCheck')"
            >推广考核</el-menu-item
          >
          <el-menu-item
            index="/accountLogHome"
            @click="openMenuItem('账户设置', '/accountLogHome')"
            >账户设置</el-menu-item
          >
        </el-sub-menu>

        <el-sub-menu index="2">
          <template #title>
            <el-icon><TrendCharts /></el-icon>
            <span>企业号</span>
          </template>
          <el-menu-item
            index="/enterpriseAccount"
            @click="openMenuItem('抖音企业号', '/enterpriseAccount')"
            >抖音企业号</el-menu-item
          >
          <el-menu-item
            index="/businessAccountVideo"
            @click="openMenuItem('企业号视频', '/businessAccountVideo')"
            >企业号视频</el-menu-item
          >
          <el-menu-item
            index="/accountVideo"
            @click="openMenuItem('推广视频', '/accountVideo')"
            >推广视频</el-menu-item
          >

          <el-menu-item
            index="/businessMsg"
            @click="openMenuItem('私信列表', '/businessMsg')"
            >私信列表</el-menu-item
          >
          <el-menu-item
            index="/businessLive"
            @click="openMenuItem('直播列表', '/businessLive')"
            >直播列表</el-menu-item
          >
        </el-sub-menu>
        <el-sub-menu index="3">
          <template #title>
            <el-icon><Compass /></el-icon>
            <span>账户罗盘</span>
          </template>
          <el-menu-item index="/semUserDay" @click="openMenuItem('日考核', '/semUserDay')"
            >日考核</el-menu-item
          >
        </el-sub-menu>

        <el-sub-menu index="4">
          <template #title>
            <el-icon><Tools /></el-icon>
            <span>系统配置</span>
          </template>
          <el-menu-item index="/semDict" @click="openMenuItem('字典管理', '/semDict')"
            >字典管理</el-menu-item
          >
          <el-menu-item
            index="/accountCheckConfig"
            @click="openMenuItem('考核成本统计', '/accountCheckConfig')"
            >考核成本统计</el-menu-item
          >
        </el-sub-menu>
      </el-menu>
    </div>
    <div :class="content">
      <div class="headTab">
        <div class="tabDiv">
          <tab></tab>
        </div>
        <div class="r-content">
          <el-dropdown>
            <span>
              <img class="user" :src="getImgSrc()" alt="头像" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>我的</el-dropdown-item>
                <el-dropdown-item>退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <!-- <div id="userInfo">
					<el-dropdown>
						<span class="username-span">
							{{测试用户}}
						</span>
						<template>
							<el-dropdown-menu>
								<el-dropdown-item command="user">个人中心</el-dropdown-item>
								<el-dropdown-item command="email65">邮箱:{{userInfo.userEmail}}</el-dropdown-item>
								<el-dropdown-item command="updatePwd">修改密码</el-dropdown-item>
								<el-dropdown-item command="logout">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div> -->
      <div class="wrapper">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenu from "./TreeMenu.vue";
import BreadCrumb from "./BreadCrumb.vue";
import Tab from "../components/Tab.vue";

export default {
  name: "home",
  components: { TreeMenu, BreadCrumb, Tab },
  setup() {
    let getImgSrc = () => {
      // 参考https://cn.vitejs.dev/guide/assets.html#new-url-url-import-meta-url
      // console.log(import.meta.url)
      // console.log(new URL("../assets/images/user.png", import.meta.url))
      return new URL("../assets/head.png", import.meta.url).href;
    };
    return {
      getImgSrc,
    };
  },
  data() {
    return {
      activeMenu: location.hash.slice(1),
      isCollapse: false,
      menuTree: [],
      userInfo: [],
      noticeCount: 0,
    };
  },
  mounted() {
    //this.getMenuList()
  },
  methods: {
    openMenuItem(name, path) {
      this.$store.state.cache.accountId = null;
      localStorage.removeItem("awemeId");
      this.$store.commit("history/addHistory", { name, path });
      this.$store.commit("history/addHistoryPath", path);
    },
    toggleNav() {
      this.isCollapse = !this.isCollapse;
    },

    // async getMenuList(){

    // 	this.menuTree = []

    // }
  },
};
</script>

<style>
.logo {
  display: flex;
  align-items: center;
  font-size: 2;
  height: 5%;
}
.img2 {
  margin: 0 10%;
  width: 20%;
  height: 50%;
}
.nav {
  position: fixed;
  width: 12%;
  height: 100vh;
  background-color: #001529;
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.3s;
}
.nav-menu {
  height: calc(100vh - 50px);
  border-right: none;
}

.basic-layout {
  position: relative;
}
.content {
  margin-left: 20%;

  &.fold {
    margin-left: 64px;
  }
  &.unfold {
    margin-left: 200px;
  }
}
.top-crumb-left {
  display: flex;
  align-items: center;
}
.bread {
  margin-left: 10%;
  padding: 0.5%;
}
.top-crumb {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 0.1% 2%;
}
.wrapper {
  background: #eef0f3;
  padding: 1%;
  height: calc(100vh - 50px);
  margin-left: 12%;
}
.main {
  background-color: #fff;
  height: 100%;
}

.notice {
  margin-right: 20px;
  line-height: 25px;
}
.username-span {
  cursor: pointer;
  color: #4091ff;
}
.r-content {
  float: right;
}
.user {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5%;
}
.tabDiv {
  float: left;
  margin-left: 3%;
  width: 50%;
}
.headTab {
  height: 4.5vh;
}
</style>
