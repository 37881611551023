<template>
  <el-row class="login-container">
    <el-col :lg="16" :md="12" class="left">
      <div>
        <div>欢迎光临</div>
        <div>推广管理系统</div>
      </div>
    </el-col>
    <el-col :lg="8" :md="12" class="right">
      <h2 class="title">欢迎回来</h2>
      <div>
        <span class="line"></span>
        <span>账号密码登录</span>
        <span class="line"></span>
      </div>
      <el-form :model="form" class="w-[250px]">
        <el-form-item prop="username">
          <el-input
            style="width: 200px"
            v-model="form.username"
            placeholder="请输入用户名"
          >
            <template #prefix>
              <el-icon>
                <user />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            style="width: 200px"
            type="password"
            v-model="form.password"
            placeholder="请输入密码"
            show-password
          >
            <template #prefix>
              <el-icon>
                <lock />
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <!-- 记住密码 -->
        <el-form-item class="isChecked">
          <!-- `checked` 为 true 或 false -->
          <el-checkbox v-model="form.checked" class="remeberPwd">记住密码</el-checkbox>
        </el-form-item>
        <!-- 登录模块 -->
        <el-form-item>
          <el-button
            round
            color="#626aef"
            class="w-[250px]"
            type="primary"
            @click="onSubmit"
            :loading="loading"
            >登 录</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

export default {
  name: "accountBasic",
  mounted() {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      if (this.form.username !== "test") {
        ElMessage({
          message: "账户或密码错误",
          type: "error",
        });
        return;
      }
      if (this.form.password !== "test123") {
        ElMessage({
          message: "账户或密码错误",
          type: "error",
        });
        return;
      }
      localStorage.setItem("userToken", "111");
      this.$router.push({
        //核心语句
        path: "/welcome",
      });
    },
  },
};
</script>

<style scoped>
.login-container {
  @apply min-h-screen bg-indigo-500;
}

.login-container .left,
.login-container .right {
  @apply flex items-center justify-center;
}

.login-container .right {
  @apply bg-light-50 flex-col;
}

.left > div > div:first-child {
  @apply font-bold text-5xl text-light-50 mb-4;
}

.left > div > div:last-child {
  @apply text-gray-200 text-sm;
}

.right .title {
  @apply font-bold text-3xl text-gray-800;
}

.right > div {
  @apply flex items-center justify-center my-5 text-gray-300 space-x-2;
}

.right .line {
  @apply h-[1px] w-16 bg-gray-200;
}
</style>
