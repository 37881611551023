// errorHandler.js
import { ElMessage } from "element-plus";

export default function errorHandler(error) {
  // 根据不同的错误类型进行处理
  if (error.response) {
    // 后端返回错误
    const { status } = error.response;
    if (status === 403) {
      // 处理权限不足的情况
      ElMessage({
        message: "无权限使用此功能",
        type: "error",
      });
    } else if (status === 500) {
      // 处理服务器内部错误
      ElMessage({
        message: "服务器异常",
        type: "error",
      });
    } else if (status !== 200) {
        // 处理服务器内部错误
        ElMessage({
          message: "服务器异常",
          type: "error",
        });
      } 
  } 
}


  function successHandler(response) {
      var systemResp=response.data
      var code=systemResp.code;
      var message=systemResp.message;
      if(code !== 200){
        //处理服务器内部错误
        ElMessage({
          message: message,
          type: "error",
        });
      }
     

}
