import Vue from 'vue'
import Vuex from 'vuex'



import getters from "./getters";

import history from './moudules/history'



const modules= {
    history
}

export default new Vuex.Store({
    modules,
    getters,
    state: {
        userInfo:{
          code:'',
          state:'',
          token:''
        },
        cache:{
          accountId:'',
          optAccountId:'',
          accountType:''        }
     
      }
})

