<template>
  <el-tabs
    type="card"
    v-if="history && history.length > 0"
    :value="currentPath"
    @tab-click="tabClick"
    @tab-remove="tabRemove"
    closable
    class="content"
  >
    <el-tab-pane
      v-for="item in history"
      :key="item.path"
      :label="item.name"
      :name="item.path"
    >
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: "Tab",
  computed: {
    history() {
      return this.$store.getters.history;
    },
    currentPath() {
      return this.$store.getters.currentPath;
    },
  },
  methods: {
    tabClick(tab, event) {
      var path = tab.props.name;
      this.$store.commit("history/addHistoryPath", path);
      if (path !== this.$route.path) {
        this.$router.push(path);
      }
    },
    tabRemove(path) {
      this.$store.commit("history/removeHistory", path);
      this.$store.commit("history/removeHistoryPath", path);
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 5;
  height: 5;
  user-select: none;
}
:deep(.el-tabs__nav-wrap) {
  height: 35px !important;
  line-height: 35px !important;
}
:deep(.el-tabs__body-wrapper) {
  height: calc(100% - 44px) !important;
}
:deep(.el-tabs__nav-next),
:deep(.el-tabs__nav-prev) {
  line-height: 35px !important;
}
:deep(.el-tabs__header) {
  height: 35px !important;
  margin: 0;
  border: none;
}
:deep(.el-tabs__item) {
  /* height: 30px;
    margin-top: 2px;
    margin-bottom: 2px;
    border: 1px solid grey !important;
    line-height: 30px;
    padding: 0 10px 0 10px !important;
    border-radius: 2px; */

  background-color: #ecf5ff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409eff;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}

:deep(.el-tabs__item:not(:last-child)) {
  margin-right: 10px;
}

:deep(.is-active) {
  border-bottom: inherit;
  background-color: lightcyan;
}
:deep(.el-tabs__nav) {
  border: none !important;
  /*height: 35px;*/
  /*1px solid #E4E7ED*/
}
</style>
